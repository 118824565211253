/* ------------------------------- Animations ---------------------- */
.AUL {
  display: inline-block;
  position: relative;
}
.AUL:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  top: 18px;
  background-color: #ed013e;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}
.AUL:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
