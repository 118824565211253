/* ------------------------------- Animations ---------------------- */
.AUL2 {
  display: inline-block;
  position: relative;
}
.AUL2:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 18px;
  background-color: #ed013e;
}
