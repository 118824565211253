.Wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
}
.COFFEE1 {
  width: 220px;
  height: 258px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-image: url(assets/COFFEE1.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  margin-left: -110px;
}
.ADD {
  width: 25%;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
/* ################################################################################## */
@media (max-width: 559px) {
  .COFFEE1 {
    display: none;
  }
  .COFFEE2 {
    position: absolute;
    width: 170px;
    height: 143px;
    background-image: url(assets/COFFEE2.png);
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    margin-top: -76px;
    margin-left: calc(50% - 85px);
  }
}
/* ################################################################################## */
@media (min-width: 560px) and (max-width: 768px) {
  .COFFEE1 {
    width: 160px;
    height: 185px;
    margin-left: -85px;
  }
}
